import axios from 'axios';

// const EXPENSE_REPORT_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/expense-reports";
// const ICONS_BASE_REST_API_URL = "http://localhost:8080/api/v1/icons";
const ICONS_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/icons";





class IconsService{

    getIcons(){
        return axios.get(ICONS_BASE_REST_API_URL)
    }

}

export default new IconsService();