
import { useState } from 'react';


const initialGameBoard = [
    [null, null, null],
    [null, null, null],
    [null, null, null]
]

//onselectsquare added as a prop but it is a function set it App.  so you are able to exectue it as a function??

export default function GameBoard({onSelectSquare, turns}){
    let gameBoard = [...initialGameBoard.map(array => [...array])];


    for (const turn of turns) {




        const { square, player } = turn;
        const { row, col } = square;


        gameBoard[row][col] = player;
    }

    return <ol id="game-board">
        {gameBoard.map((row, rowIndex) =>  <li key={rowIndex}>
        <ol>
            {row.map((playerSymbol, colIndex) => 
            <li key={colIndex}>
                <button onClick={() => onSelectSquare(rowIndex, colIndex)}>{playerSymbol}</button>
            </li>)}
        </ol>
        </li>)}
    </ol>
}