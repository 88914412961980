import axios from 'axios';
import Dotenv from 'dotenv';



class OpenAIService{
  // Load environment variables from .env file


fetchData = async (input: string) => {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: 'gpt-3.5-turbo',
        messages: [{"role": "user", "content": `${input}`}],
        max_tokens: 200,
        // n: 1,
        // stop: ".",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + process.env.REACT_APP_OPENAI_API_KEY,
        },
      }
    );
    return response.data.choices[0].message.content;
  };
}

export default new OpenAIService();