import axios from 'axios';

// const EXPENSE_REPORT_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/expense-reports";
// const CALENDAR_EVENTS_BASE_REST_API_URL = "http://localhost:8080/api/v1/calendar-events";
const CALENDAR_EVENTS_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/calendar-events";





class CalendarEventsService{

    getAllCalendarEvents(){
        return axios.get(CALENDAR_EVENTS_BASE_REST_API_URL)
    }

}

export default new CalendarEventsService();