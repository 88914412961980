// src/DrawerMenu.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./DrawerMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHome,
  faPeopleGroup,
  faMap,
  faMoneyBill1Wave,
  faFileInvoiceDollar,
  faCalendarDays,
  faChessBoard,
  faHeadSideVirus,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";

const DrawerMenu = ({ addPin }) => {
  const [open, setOpen] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const handleAddPin = () => {
    addPin(parseFloat(longitude), parseFloat(latitude));
  };

  return (
    <div>
      {/* Sidebar */}
      <div className={`sidebar ${open ? "open" : ""}`}>
        {/* Hamburger Button */}
        {/* <button className="hamburger btn btn-primary" onClick={toggleSidebar}>
          ☰
        </button> */}

        {/* Menu Links */}
        <ul>
          <li>
            <button className="hamburger" onClick={toggleSidebar}>
              ☰
            </button>
          </li>
          <li>
            <Link to="/home" className={`menu-link ${open ? "open" : ""}`}>
              <FontAwesomeIcon icon={faHome} className="menu-icon" />
              {open && <span className="navLocs">Home</span>}
            </Link>
          </li>
          <li>
            <Link to="/users" className={`menu-link ${open ? "open" : ""}`}>
              <FontAwesomeIcon icon={faPeopleGroup} className="menu-icon" />
              {open && <span className="navLocs">Users</span>}
            </Link>
          </li>
          <li>
            <Link to="/income" className={`menu-link ${open ? "open" : ""}`}>
              <FontAwesomeIcon icon={faMoneyBill1Wave} className="menu-icon" />
              {open && <span className="navLocs">Income</span>}
            </Link>
          </li>
          <li>
            <Link
              to="/expenseReports"
              className={`menu-link ${open ? "open" : ""}`}
            >
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                className="menu-icon"
              />
              {open && <span className="navLocs">Expense Reports</span>}
            </Link>
          </li>
          <li>
            <Link to="/calendar" className={`menu-link ${open ? "open" : ""}`}>
              <FontAwesomeIcon icon={faCalendarDays} className="menu-icon" />
              {open && <span className="navLocs">Calendar</span>}
            </Link>
          </li>
          <li>
            <Link
              to="/tic-tac-toe"
              className={`menu-link ${open ? "open" : ""}`}
            >
              <FontAwesomeIcon icon={faChessBoard} className="menu-icon" />
              {open && <span className="navLocs">Tic Tac Toe</span>}
            </Link>
          </li>
          <li>
            <Link to="/open-ai" className={`menu-link ${open ? "open" : ""}`}>
              <FontAwesomeIcon icon={faHeadSideVirus} className="menu-icon" />
              {open && <span className="navLocs">Chat GPT</span>}
            </Link>
          </li>
          <li>
            <Link to="/react-art" className={`menu-link ${open ? "open" : ""}`}>
              <FontAwesomeIcon icon={faPalette} className="menu-icon" />
              {open && <span className="navLocs">React Art</span>}
            </Link>
          </li>
          <li>
            <Link
              to="/open-layers"
              className={`menu-link ${open ? "open" : ""}`}
            >
              <FontAwesomeIcon icon={faMap} className="menu-icon" />
              {open && <span className="navLocs">Map</span>}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DrawerMenu;
