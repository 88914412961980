import AuthInputs from './components/AuthInputs.jsx';
import Header from './components/Header.jsx';
import './index.css';


export default function ReactArt() {
  return (
    <>
      <Header />
      <main>
        <AuthInputs />
      </main>
    </>
  );
}