// import React, { useState , useEffect} from 'react'
// import ExpenseReportService from '../services/ExpenseReportService'
// import { Link } from 'react-router-dom';
// import { Button } from 'bootstrap';

// function ExpenseReportComponent() {

//     const [expenseReports, setExpenseReports] = useState([])

//     useEffect(() => {
//         ExpenseReportService.getAllExpenseReports().then((response) => {
//             setExpenseReports(response.data)
//             console.log(response.data);
//         }).catch(error => {
//             console.log(error);
//         })
//     }, [])
    


//   return (
//     <div className="container">
//         <h2 className = "text-center"> List Expense Reports</h2>
//         <Link to = "/add-expense-report" className = "btn btn-primary mb-2">Add Expense Report</Link>
//         <table className="table table-bordered table-striped">
//             <thead>
//                 <th>Id</th>
//                 <th>Name</th>
//                 <th>Amount</th>
//                 <th>Created On</th>
//                 <th>Updated On</th>
//                 <th>Vendor</th>
//                 <th>Priority</th>
//                 <th></th>
//             </thead>
//             <tbody>
//                 {
//                     expenseReports.map(
//                         expenseReport =>
//                         <tr key = {expenseReport.id}>
//                             <td>{expenseReport.id}</td>
//                             <td>{expenseReport.name}</td>
//                             <td>{expenseReport.amount}</td>
//                             <td>{expenseReport.createdOn}</td>
//                             <td>{expenseReport.updatedOn}</td>
//                             <td>{expenseReport.vendor}</td>
//                             <td>{expenseReport.priority}</td>
//                             <td>{<button id="delete" class = "btn btn-danger">Delete</button>}</td>

//                         </tr>
//                     )
//                 }
//             </tbody>
//         </table>
//     </div>
//   )
// }

// export default ExpenseReportComponent




import React, { useState, useEffect } from 'react';
import ExpenseReportService from '../services/ExpenseReportService';
import { Link } from 'react-router-dom';

function ExpenseReportComponent() {
  const [expenseReports, setExpenseReports] = useState([]);

  useEffect(() => {
    fetchExpenseReports();
  }, []);

  const fetchExpenseReports = () => {
    ExpenseReportService.getAllExpenseReports()
      .then((response) => {
        setExpenseReports(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    ExpenseReportService.deleteExpenseReport(id)
      .then(() => {
        console.log(`Expense Report with ID ${id} has been deleted.`);
        fetchExpenseReports(); // Refresh the expense reports after deletion
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="container">
      <h2 className="text-center">List Expense Reports</h2>
      <Link to="/addExpenseReport" className="btn btn-primary mb-2">
        Add Expense Report
      </Link>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Amount</th>
            <th>Created On</th>
            <th>Updated On</th>
            <th>Vendor</th>
            <th>Priority</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {expenseReports.map((expenseReport) => (
            <tr key={expenseReport.id}>
              <td>{expenseReport.id}</td>
              <td>{expenseReport.name}</td>
              <td>{expenseReport.amount}</td>
              <td>{expenseReport.createdOn}</td>
              <td>{expenseReport.updatedOn}</td>
              <td>{expenseReport.vendor}</td>
              <td>{expenseReport.priority}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(expenseReport.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ExpenseReportComponent;
