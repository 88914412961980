import { useState } from 'react';

import Player from './src/components/Player.jsx';
import GameBoard from './src/components/GameBoard.jsx';
import GameRestart from './src/components/GameRestart.jsx';
import Log from './src/components/Log.jsx';
import { WINNING_COMBINATIONS } from './src/components/winning-combinations.js';



function TicTacToe() {

  const [activePlayer, setActivePlayer] = useState('X');
  const [gameTurns, setGameTurns] = useState([]);
  const [winner, setWinner ] = useState(null);
  let localWinner = null;
  let localWinner2 = null;
  let localWinner3 = null;
  let totalWinner = null;
  let render = null;


  function reset() {
    let newList = [];

    setWinner((currWinner) => null);
    setGameTurns([]);
    // render = true;

  }


  function handleSelectSquare(rowIndex, colIndex) {
    setActivePlayer((curActivePlayer) => curActivePlayer ==='X' ? 'O' : 'X');
    setGameTurns((prevTurns) => {

      const updatedTurns = [{ square: {row: rowIndex, col: colIndex}, player: activePlayer }, ...prevTurns];

      WINNING_COMBINATIONS.forEach(combo => {
        updatedTurns.forEach(turn => {

          if(turn.square.row === combo[0].row && turn.square.col === combo[0].column ) {
            localWinner = turn.player;
          }
          if(turn.square.row === combo[1].row && turn.square.col === combo[1].column ){
            localWinner2 = turn.player;
          }
          if(turn.square.row === combo[2].row && turn.square.col === combo[2].column )
          localWinner3 = turn.player
        })

        if(localWinner === localWinner2 && localWinner === localWinner3) {
          totalWinner = localWinner;
        }

        })
      

        if(totalWinner){
          setWinner((currWinner) => totalWinner);
          // let array = [];
          // return array;

        } 
      return updatedTurns;
    });
  
  }
  

  return (

   <main>
    <div id="game-container">
      <ol id="players" className="highlight-player">
        <Player initialName="Player1" playerSymbol = "X" isActive={activePlayer === 'X'}/>
        <Player initialName="Player2" playerSymbol = "O" isActive={activePlayer === 'O'}/>
      </ol>

      {winner && winner != "rerender" ? (
<GameRestart startOver={reset}/>
) : null}

      <GameBoard key={render} onSelectSquare={handleSelectSquare} turns={gameTurns}/>

    </div>
    <Log turns={gameTurns} />
   </main>

  )
}

export default TicTacToe
