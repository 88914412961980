import React, { useState , useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoginService from '../services/LoginService.ts';
import {getCookie} from 'typescript-cookie';

const LoginComponent = () => {

    const { userId} = useParams();

    const [userName, setUserName] = useState('');
    // const [userId] = useParams();
    const [password, setPassword] = useState('');

    const history = useNavigate();


    const loginRequest = (e) => {
        e.preventDefault();
        
        const user = {userName, password};

        LoginService.loginRequest(user).then((response) => {
            console.log(response.data);
            alert(JSON.stringify(window.sessionStorage));
            let xsrf = getCookie('XSRF-TOKEN');
            window.sessionStorage.setItem('XSRF-TOKEN', xsrf);
            window.alert(xsrf);
            alert(JSON.stringify(window.sessionStorage));

            history(`/home`);
        }).catch(error => {
            console.log(error);
        })

        console.log(user);
    }

    



  return (
    <div>
    <br></br><br></br>
    <div className = "container">
        <div className ="row">
            <div className = "card col-md-6 offset-md-3 offset-md-3">
                <h2 className = "text-center">Login</h2>
                <div className = "card-body">
                    <form>
                        <div className = "form-group mb-2">
                            <label className = "form-label">User Name : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "userName"
                                onChange = {(e) => setUserName(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Password : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter item"
                                name = "password"
                                onChange = {(e) => setPassword(e.target.value)}
                            >
                            </input>
                        </div>
                      
                        <button className = "btn btn-success" onClick = {(e) => loginRequest(e)}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default LoginComponent