import { useState } from 'react';

export default function Player({initialName, playerSymbol, isActive}) {
    const [isEditing, setIsEditing] = useState(false);
    const [playerName, setPlayerName] = useState(initialName);

    function handleEditClick(){
        setIsEditing((editing) => !editing);
    }

    function handleChangeName(event) {
        setPlayerName(event.target.value);
    }


    let edittablePlayerName = !isEditing? <span className='player-name'>{playerName}</span> : <input type="text" required value={playerName} onChange={handleChangeName}></input>;

    return (
        <li className={isActive? 'active' : undefined}>
        <span className="player">
          {edittablePlayerName}
          <span className="player-symbol">{playerSymbol}</span>
        </span>
        <button onClick={handleEditClick}>{isEditing? 'Save' : 'Edit'}</button>
      </li>
    )
}