import ReactDOM from 'react-dom/client';

import TicTacToe from './TicTacToe.jsx';
import './index.css';


export default function Index() {
// ReactDOM.createRoot(document.getElementById('root')).render(
    return(
<>
<div className="tic-tac-toe">
<header className="tic-tac-toe-header"><h1>Tic Tac Toe</h1></header>
<TicTacToe />
</div>
</>);
// root.render(<TicTacToe />);

}
