import axios from 'axios';

// const EXPENSE_REPORT_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/expense-reports";
// const BUDGETS_BASE_REST_API_URL = "http://localhost:8080/api/v1/budgets";
const BUDGETS_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/budgets";





class BudgetsService{

    getAllBudgetItems(userId){
        return axios.get(BUDGETS_BASE_REST_API_URL + '/get?id=' + userId)
    }

    createBudgetItem(budget){
        return axios.post(BUDGETS_BASE_REST_API_URL + '/create', budget)
    }

    deleteBudgetItem(id){
        return axios.get(BUDGETS_BASE_REST_API_URL+ '/delete?id='+ id)
    }
}

export default new BudgetsService();