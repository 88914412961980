import React, { useState, useEffect } from 'react';
import axios from "axios";
import UserService from '../services/UsersService';
import {getCookie} from 'typescript-cookie';

// import otter from '../images/otter.avif';

function SplashPage() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    alert(JSON.stringify(getCookie()));
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    UserService.getUsers()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="splash-page">
      <h1>Welcome to the Splash Page</h1>
      <div className="user-tiles-container">
        {users.map((user) => (
          <div className="user-tile" key={user.id}>
            {/* <h2>{user.name}</h2> */}
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{user.name}</h5>
                </div>
                <a href={`/budgetsAll/${user.id}`}>
                <img className="card-img-top" src={process.env.PUBLIC_URL + user.imgPath} alt="Card image cap" />
                </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SplashPage;
