import React, { useState , useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import ExpenseReportService from '../services/ExpenseReportService'

const AddExpenseReportComponent = () => {

    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [vendor, setVendor] = useState('');
    const [priority, setPriority] = useState('');
    const history = useNavigate();


    const saveExpenseReport = (e) => {
        e.preventDefault();
        
        const expenseReport = {name, amount, vendor, priority};

        ExpenseReportService.createExpenseReport(expenseReport).then((response) => {
            console.log(response.data);
            history('/expenseReports');
            // history.push('/expenseReports');
        }).catch(error => {
            console.log(error);
        })

        console.log(expenseReport);
    }



  return (
    <div>
    <br></br><br></br>
    <div className = "container">
        <div className ="row">
            <div className = "card col-md-6 offset-md-3 offset-md-3">
                <h2 className = "text-center">Add Expense Report</h2>
                <div className = "card-body">
                    <form>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Name : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "name"
                                value = {name}
                                onChange = {(e) => setName(e.target.value)}
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Amount : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense amount"
                                name = "amount"
                                value = {amount}
                                onChange = {(e) => setAmount(e.target.value)}
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Vendor : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter vendor"
                                name = "vendor"
                                value = {vendor}
                                onChange = {(e) => setVendor(e.target.value)}
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Priority : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter priority"
                                name = "priority"
                                value = {priority}
                                onChange = {(e) => setPriority(e.target.value)}
                            >
                            </input>
                        </div>
                        <button className = "btn btn-success" onClick = {(e) => saveExpenseReport(e)}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default AddExpenseReportComponent