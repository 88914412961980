import React, { useState , useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import BudgetsService from '../services/BudgetsService'

const AddBudgetLineComponent = () => {

    const { userId} = useParams();

    const [name, setName] = useState('');
    // const [userId] = useParams();
    const [item, setItem] = useState('');
    const [amount, setAmount] = useState('');
    const [vendor, setVendor] = useState('');
    const history = useNavigate();


    const saveBudgetLineItem = (e) => {
        e.preventDefault();
        
        const budget = {name, userId, item, amount, vendor};

        BudgetsService.createBudgetItem(budget).then((response) => {
            console.log(response.data);
            history(`/budgets/${userId}`);
        }).catch(error => {
            console.log(error);
        })

        console.log(budget);
    }

    



  return (
    <div>
    <br></br><br></br>
    <div className = "container">
        <div className ="row">
            <div className = "card col-md-6 offset-md-3 offset-md-3">
                <h2 className = "text-center">Add Budget Line Item</h2>
                <div className = "card-body">
                    <form>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Name : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "name"
                                onChange = {(e) => setName(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Item : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter item"
                                name = "item"
                                value = {item}
                                onChange = {(e) => setItem(e.target.value)}
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Amount : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense amount"
                                name = "amount"
                                value = {amount}
                                onChange = {(e) => setAmount(e.target.value)}
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Vendor : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter vendor"
                                name = "vendor"
                                value = {vendor}
                                onChange = {(e) => setVendor(e.target.value)}
                            >
                            </input>
                        </div>
                        <button className = "btn btn-success" onClick = {(e) => saveBudgetLineItem(e)}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default AddBudgetLineComponent