import axios from 'axios';

// const EXPENSE_REPORT_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/expense-reports";
const LOGIN_BASE_REST_API_URL = "http://localhost:8080/api/v1/users/login";
// const ICONS_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/icons";





class LoginService{

    loginRequest(user){
        return axios.post(LOGIN_BASE_REST_API_URL, user,{ withCredentials: true });
    }

}

export default new LoginService();