import React, { useState, useEffect } from 'react';
import UsersService from '../services/UsersService';
import { Link, useParams, useNavigate } from 'react-router-dom';



function UsersComponent() {
  const [Users, setUsers] = useState([]);
  const { userId } = useParams();
  const history = useNavigate();


  useEffect(() => {
    fetchUsers(userId);
  }, []);

  const fetchUsers = () => {
    UsersService.getUsers()
      .then((response) => {
        setUsers(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    UsersService.deleteUser(id)
      .then(() => {
        console.log(`User with ID ${id} has been deleted.`);
        fetchUsers(); // Refresh the expense reports after deletion
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getBudget = (userId) => {
    history(`/budgetsAll/${userId}`);
  };

  return (
    <div className="container">
      <h2 className="text-center">List Users</h2>
      <Link to={`/users/add`} className="btn btn-primary mb-2">
        Add Users
      </Link>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Img Path</th>
            <th>Role</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Users.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.imgPath}</td>
              <td>{user.role}</td>
              <td>
                <button
                  className="btn btn-info"
                  onClick={() => getBudget(user.id)}
                >
                  Budget
                </button>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(user.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersComponent;


