import React, { useState , useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import UsersService from '../services/UsersService'

const AddUserComponent = () => {



    const [name, setName] = useState('');
    const [imgPath, setImgPath] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');

    const history = useNavigate();


    const saveUser = (e) => {
        e.preventDefault();
        
        const user = {name, imgPath, email, username, password, role};

        UsersService.createUser(user).then((response) => {
            console.log(response.data);
            history(`/Users`);
        }).catch(error => {
            console.log(error);
        })

        console.log(user);
    }



  return (
    <div>
    <br></br><br></br>
    <div className = "container">
        <div className ="row">
            <div className = "card col-md-6 offset-md-3 offset-md-3">
                <h2 className = "text-center">Add User</h2>
                <div className = "card-body">
                    <form>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Name : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "name"
                                onChange = {(e) => setName(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Image Path : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "imgPath"
                                onChange = {(e) => setImgPath(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Email : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "email"
                                onChange = {(e) => setEmail(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">User Name : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "username"
                                onChange = {(e) => setUserName(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Password : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "password"
                                onChange = {(e) => setPassword(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                        <div className = "form-group mb-2">
                            <label className = "form-label">Role : </label><br></br>
                            <input
                                type = "text"
                                placeholder = "Enter expense name"
                                name = "role"
                                onChange = {(e) => setRole(e.target.value)}
                                // disabled
                            >
                            </input>
                        </div>
                       
                        <button className = "btn btn-success" onClick = {(e) => saveUser(e)}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
        
    </div>
    </div>
  )
}

export default AddUserComponent