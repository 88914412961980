import React from "react";

function OpenLayers() {
  return (
    <div className="OpenLayers">
      {/* <Sidebar /> */}
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1>My OpenLayers Map</h1>
      </header>
      <div className="Map-container">{/* <OpenLayersMap /> */}</div>
    </div>
  );
}

export default OpenLayers;
