import React, { useState, useEffect } from 'react';
import BudgetsComponent from '../components/BudgetComponent';
import IconComponent from '../components/IconComponent';
import AddBudgetLineComponent from '../components/AddBudgetLineComponent';
import { Link, useParams } from 'react-router-dom';
import UsersService from '../services/UsersService';
import IconsService from '../services/IconsService';




function BudgetsPage() {
    const { userId } = useParams();
    const [user, setUser] = useState([]);
    const [name, setName] = useState('');
    const [icons, setIcons] = useState([]);
    const [selectedIcon, setSelectedIcon] = useState('');


    useEffect(() => {
        fetchUser();
        fetchIcons();
      }, []);

    const fetchIcons = () => {
        IconsService.getIcons()
        .then((response) => {
            setIcons(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const fetchUser = () => {
        UsersService.getUser(userId)
          .then((response) => {
            setUser(response.data);
            setName(response.data[0].name);
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const handleIconChange = event => {
        setSelectedIcon(event.target.value);
      };
  
    return (
      <div> 
        <h1>{`${name}'s Budget`} </h1>
        <BudgetsComponent userId={userId} />
        <AddBudgetLineComponent userId={userId} />

</div>
    );
  }
  
  export default BudgetsPage;
