import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ExpenseReportComponent from "./components/ExpenseReportComponent";
import BudgetComponent from "./components/BudgetComponent";
import BudgetsPageComponent from "./components/BudgetsPageComponent";
import AddBudgetLineComponent from "./components/AddBudgetLineComponent";
import FooterComponent from "./components/FooterComponent";
import HeaderComponent from "./components/HeaderComponent";
import Calendar from "./components/Calendar";
import OpenAIComponent from "./components/OpenAIComponent";
import UsersComponent from "./components/UsersComponent";
import LoginComponent from "./components/LoginComponent";
import AddExpenseReportComponent from "./components/AddExpenseReportComponent";
import AddUserComponent from "./components/AddUserComponent";
import HomePage from "./components/HomePage";
import DrawerMenu from "./components/DrawerMenu";
import Index from "./components/TicTacToe/index.jsx";
import ReactArt from "./components/ReactArt/src/ReactArt.jsx";
import OpenLayers from "./components/OpenLayers/OpenLayers.js";

function App() {
  return (
    <div className="wrapper">
      {/* <div> */}
      <Router>
        <HeaderComponent />
        <DrawerMenu />
        <div className="container">
          <Routes>
            <Route path="/" element={<LoginComponent />}></Route>
            <Route
              path="/expenseReports"
              element={<ExpenseReportComponent />}
            ></Route>
            <Route
              path="/addExpenseReport"
              element={<AddExpenseReportComponent />}
            ></Route>
            <Route path="/open-ai" element={<OpenAIComponent />}></Route>
            <Route path="/budgets/:userId" element={<BudgetComponent />} />
            <Route
              path="/budgets/add/:userId"
              element={<AddBudgetLineComponent />}
            />
            <Route
              path="/budgetsAll/:userId"
              element={<BudgetsPageComponent />}
            />
            <Route path="/users" element={<UsersComponent />} />
            <Route path="/users/add" element={<AddUserComponent />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/tic-tac-toe" element={<Index />} />
            <Route path="/react-art" element={<ReactArt />} />
            <Route path="/open-layers" element={<OpenLayers />} />
          </Routes>
        </div>
        {/* <FooterComponent/> */}
        <br></br>
        <br></br>
      </Router>
    </div>
  );
}

export default App;
