import React, { useState, useEffect } from 'react';
import BudgetsService from '../services/BudgetsService';
import { Link, useParams } from 'react-router-dom';
import ExpenseReportService from '../services/ExpenseReportService';



function BudgetsComponent() {
  const [budgets, setBudgets] = useState([]);
  const { userId } = useParams();

  const [name, setname] = useState('');
  const [amount, setamount] = useState('');
  const [vendor, setvendor] = useState('');

  useEffect(() => {
    fetchBudgets(userId);
  }, []);

  const fetchBudgets = (userId) => {
    BudgetsService.getAllBudgetItems(userId)
      .then((response) => {
        setBudgets(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    BudgetsService.deleteBudgetItem(id)
      .then(() => {
        console.log(`Budget Line with ID ${id} has been deleted.`);
        fetchBudgets(userId); // Refresh the expense reports after deletion
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const payBudgetLine = (budget) => {
    setname(budget.name);
    setamount(budget.amount);
    setvendor(budget.vendor);

    const expenseReport = {
      name: budget.name,
      amount: budget.amount,
      vendor: budget.vendor
    };

    ExpenseReportService.createExpenseReport(expenseReport)
      .then((response) => {
        console.log(response.data);
        // history.push('/expenseReports');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <h2 className="text-center">List Budget</h2>
      <Link to={`/budgets/add/${userId}`} className="btn btn-primary mb-2">
        Add Budget Line
      </Link>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Item</th>
            <th>Vendor</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {budgets.map((budget) => (
            <tr key={budget.id}>
              <td>{budget.item}</td>
              <td>{budget.vendor}</td>
              <td>{budget.amount}</td>
              <td>
                <button
                  className="btn btn-info"
                  onClick={() => payBudgetLine(budget)}
                >
                  Pay
                </button>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(budget.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BudgetsComponent;


