import axios from 'axios';

// const EXPENSE_REPORT_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/expense-reports";
// const USERS_BASE_REST_API_URL = "http://localhost:8080/register";
// const USERS_BASE_REST_API_URL = "http://localhost:8080/api/v1/users";

const USERS_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/users";





class UsersService{

    getUsers(){
        return axios.get(USERS_BASE_REST_API_URL)
    }

    createUser(user){
        return axios.post(USERS_BASE_REST_API_URL, user)
    }

    deleteUser(id){
        return axios.get(USERS_BASE_REST_API_URL+ '/delete?id='+ id)
    }

    getUser(userId){
        return axios.get(USERS_BASE_REST_API_URL+ '/get?id='+ userId)
    }
}

export default new UsersService();