import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../App.css';
import CalendarEventsService from '../services/CalendarEventsService';

const CalendarComponent = () => {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);



  useEffect(() => {
    // Fetch events data from your database or API
    // and update the events state
    fetchEventsData()
    //   .then((data) => setEvents(data))
    //   .catch((error) => console.log(error));
  }, []);

  const fetchEventsData = () => {
    CalendarEventsService.getAllCalendarEvents()
    .then((response) => {
        setEvents(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // Implement your logic to fetch events data from the database or API
    // and return the data
    // return new Promise((resolve, reject) => {
    //   // Simulating a fetch request with a delay
    //   setTimeout(() => {
    //     const eventsData = [
    //       { date: new Date(2023, 6, 1), title: 'Event 1' },
    //       { date: new Date(2023, 6, 10), title: 'Event 2' },
    //       // Add more events data as needed
    //     ];
    //     resolve(eventsData);
    //   }, 1000);
    // });
  };

  const onChange = (selectedDate) => {
    setDate(selectedDate);
    window.alert('Date is: ' + selectedDate);
  };

  const tileContent = ({ date, view }) => {
    // Custom rendering for the calendar tiles
    if (view === 'month') {
        const event = events.find((event) => {
            const eventDate = new Date(event.date);

            return eventDate.toISOString().substring(0, 10) === date.toISOString().substring(0, 10);
          });
      return event ? <div><p className="event-title">{event.eventName}</p><p className="event-title">{event.eventName}</p></div> : null;
    }
    return null;
  };

  return (
    <div className="custom-calendar-container">
      <h2>Calendar</h2>
      <div className="custom-calendar">
        <Calendar className="custom-calendar-body" onChange={onChange} value={date} tileContent={tileContent} />
      </div>
    </div>
  );
};

export default CalendarComponent;
