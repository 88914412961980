import axios from 'axios';

const EXPENSE_REPORT_BASE_REST_API_URL = "https://plannit-jw.herokuapp.com/api/v1/expense-reports";
// const EXPENSE_REPORT_BASE_REST_API_URL = "http://localhost:8080/api/v1/expense-reports";



class ExpenseReportService{

    getAllExpenseReports(){
        return axios.get(EXPENSE_REPORT_BASE_REST_API_URL)
    }

    createExpenseReport(expenseReport){
        return axios.post(EXPENSE_REPORT_BASE_REST_API_URL, expenseReport)
    }

    deleteExpenseReport(id){
        return axios.get(EXPENSE_REPORT_BASE_REST_API_URL+ '/delete?id='+ id)
    }
}

export default new ExpenseReportService();